<div class="errors-holder" [class.mobile]="deviceService.isMobile()" [class.show]="errorsArray">
  <div class="subscribe-error" @enterAnimation
       *ngFor="let error of errorsArray; trackBy: trackByFn">
    <div class="close" (click)="closeNotification(error)">
      <svg viewBox="0 0 15 15">
        <use [attr.xlink:href]="svgSetIcon('close')"></use>
      </svg>
    </div>
    <div class="data">
      <div class="title">{{'error-notificator.' + error.type | translate}}</div>
      <div class="link" (click)="toggleChat(error)">{{'error-notificator.report-error' | translate}}</div>
      <div class="additional-info" [class.show]="showInfo[error.id]?.status">
        <a class="link"
           href="https://sentry.onclass.tech/organizations/onclass/issues/?query={{showInfo[error.id]?.info}}"
           target="_blank">{{showInfo[error.id]?.info}}</a>
      </div>
    </div>
  </div>
</div>

