import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, concatMap, delay, take } from 'rxjs/operators';

const RETRY_TIME = 3000;
const RETRY_COUNT = 5;

@Injectable()
export class DataRetrievalInterceptor implements HttpInterceptor {
  constructor(public errorNotificationService: ErrorNotificationService) {}

  private retryStatusCodes = [403, 504, 503];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      return next.handle(request);
    }
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          concatMap((error, retryCount) => {
            if (
              retryCount <= RETRY_COUNT &&
              error instanceof HttpErrorResponse &&
              this.retryStatusCodes.includes(error.status)
            ) {
              console.log(`Attempt ${retryCount + 1}: retrying...`);
              this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
              return of(null).pipe(delay(RETRY_TIME)); // Задержка перед следующей попыткой
            }
            return throwError(() => new Error('Failed to fetch data after ' + RETRY_COUNT + ' retries'));
          }),
          take(RETRY_COUNT), // Общее число попыток, включая первую
        ),
      ),
    );
  }
}
