import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { DataRetrievalInterceptor } from '@core/interceptors/data-retrieval.interceptor';
import { accessTokenInterceptor } from './access-token.interceptor';
import { CachingInterceptor } from './cache.interceptor';
import { CorsPatchInterceptor } from './cors-patch.interceptor';
import { InternetInterceptor } from './internet.interceptor';
import { Lingo2ErrorsInterceptor } from './lingo2-errors.interceptor';
import { LocaleInterceptor } from './locale.interceptor';
import { ProfilerInterceptor } from './profiler.interceptor';
import { RequesterUrlInterceptor } from './requester-url.interceptor';
import { TenantInterceptor } from './tenant.interceptor';
import { UnauthorizedInterceptor } from './unauthorized.interceptor';
import { VersionInterceptor } from './version.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  provideHttpClient(withInterceptors([accessTokenInterceptor])),
  { provide: HTTP_INTERCEPTORS, useClass: CorsPatchInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: Lingo2ErrorsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequesterUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DataRetrievalInterceptor, multi: true },
];
